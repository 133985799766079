import ProjectsList from 'components/projects-list/projects-list';
import './projects.css';
import { projects } from 'data';
import { Project } from 'types/types';
import { useState } from 'react';

const SHOWN__PROJECTS_QTY = 4;

const compareItems = (a: Project, b: Project) => b.id - a.id;
const sortedProjects = projects.sort(compareItems);

const getShownProjects = (length: number): Project[] => sortedProjects.slice(0, length);

function Projects(): JSX.Element {
  const [projectsState, setProjectsState] = useState<Project[]>(getShownProjects(SHOWN__PROJECTS_QTY));

  return (
    <section className='projects'>
      <div className='container'>
        <div className='projects__inner'>
          <h2 className='projects__title secondary-heading'>Недавние работы</h2>
          <p className='projects__text'>Здесь живут проекты, над которыми я работал в последнее время и которыми хотелось бы поделиться. </p>
          <ProjectsList projects={projectsState}/>
          <button
            type='button'
            className={`projects__more button-basic
              ${projectsState.length === sortedProjects.length ? 'projects__more--hidden' : ''}`}
            onClick={() => setProjectsState(getShownProjects(projectsState.length + SHOWN__PROJECTS_QTY))}
          >
            Показать ещё
          </button>
        </div>
      </div>
    </section>
  );
}

export default Projects;
