import { AppRoute } from 'const';
import { Link } from 'react-router-dom';
import './feedback.css';

function Feedback(): JSX.Element {

  return (
    <section className='feedback'>
      <div className='container'>
        <div className='feedback__inner'>
          <h2 className='feedback__title tertiary-heading'>Хотели бы задать вопрос или обсудить проект?</h2>
          <p className='feedback__text'>Жмите кнопку, а я пока сделаю кофе.</p>
          <Link
            to={AppRoute.Contact}
            className='feedback__link button-basic'
          >
            Написать
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Feedback;
