import Logo from 'components/logo/logo';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';
import { animated, config, useSpring } from '@react-spring/web';
import './site-header.css';
import ButtonBasic from 'components/button-basic/button-basic';

function SiteHeader(): JSX.Element {
  const ref = useRef(null);
  const [menuState, setMenuState] = useState<boolean>(true);
  const [styles, api] = useSpring(() => ({
    fontSize: menuState ? 0 : 1,
    reset: true,
    // reverse: !menuState,
    inmediate: !ref.current
  }), [menuState]);

  const handleMenuClick = (): void => {
    setMenuState(!menuState);
  };

  return (
    <header className='page-header header'>
      <div className='container'>
        <div className='header__inner'>
          <nav className='header__nav'>
            <div className='header__wrapper'>
              <Logo block='header'/>
              <button
                className={`header__menu-button header__menu-button--${menuState ? 'closed' : 'opened'}`}
                type='button'
                onClick={handleMenuClick}
              >
                <span/>
              </button>
            </div>
            <animated.ul
              ref={ref}
              style={{fontSize: styles.fontSize.to((unit) => `${unit}em`)}}
              className='header__menu-list list-reset'
            >
              <li className='header__menu-item'>
                <a
                  href='https://htmlacademy.ru/profile/id1858125/certificates'
                  className='header__menu-link link-reset'
                  target='_blank'
                  rel='noreferrer'
                >
                  Сертификаты
                </a>
              </li>
              <li className='header__menu-item'>
                <a
                  href='./files/kozachenko_resume.pdf'
                  className='header__menu-link link-reset'
                  target='_blank'
                  rel='noreferrer'
                >
                  Загрузить резюме
                </a>
              </li>
              <li className='header__menu-item'>
                <Link
                  to={AppRoute.Contact}
                  className='header__menu-link link-reset button-basic'
                >
                  Написать
                  {/* <ButtonBasic mainColor='white' animationColor='rgba(111, 79, 168, 0.9)'>Написать</ButtonBasic> */}
                </Link>
              </li>
            </animated.ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export {SiteHeader};
