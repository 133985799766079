import { useState } from 'react';
import { adjectives } from 'data';
import { getRandomInteger } from 'utils';

type GoodWishProps = {
  block?: string;
}

// const getWords = (word: string) => {
//   const a = [];
//   for (let i = 0; i <= 3; i++) {
//     a.push(shuffleArray([...word]).join(''));
//   }
//   return [...a, word];
// };

const getRandomAdjective = () => adjectives[getRandomInteger(0, adjectives.length - 1)];

// const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

function GoodWish({block}: GoodWishProps): JSX.Element {
  const [wishState, setWishState] = useState<string>(getRandomAdjective());

  // const changeText = () => {
  //   const randomWord = getWords(getRandomAdjective());
  //   randomWord.forEach(async (word) =>
  //     void (async () => {
  //       await delay(3000);
  //       setWishState(word);
  //     })()
  //   );
  // };

  setTimeout(() => {
    setWishState(getRandomAdjective());
  }, 5000);

  return (
    <p className={`${block ? `${block}__wish` : ''} wish`}>{`${wishState} вам дня!`}</p>
  );
}

export default GoodWish;
