const getRandomInteger = (min: number, max: number) => {
  const result = Math.floor(Math.random() * (max - min + 1) + min);
  return result;
};

const shuffleArray = (array: string[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = getRandomInteger(0, i);
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export {getRandomInteger, shuffleArray};
