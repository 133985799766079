import GoodWish from 'components/good-wish/good-wish';
import Logo from 'components/logo/logo';
import './site-footer.css';

function SiteFooter(): JSX.Element {
  return (
    <footer className='page-footer footer'>
      <div className='container'>
        <div className='footer__inner'>
          <Logo block='footer'/>
          <GoodWish block='footer'/>
          <div className='footer__social-wrapper'>
            <a
              className='footer__social-link footer__social-telegram'
              aria-label='Напишите мне в Telegram!'
              href='tg://resolve?domain=itwaslazyday'
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg width='30' height='30'>
                <use xlinkHref='img/sprite.svg#telegram'/>
              </svg>
            </a>
            <a
              className='footer__social-link footer__social-github'
              aria-label='Мой профиль на GitHub'
              target="_blank"
              href='https://github.com/itwaslazyday'
              rel="noopener noreferrer"
            >
              <svg width='30' height='30'>
                <use xlinkHref='img/sprite.svg#github'/>
              </svg>
            </a>
          </div>
          <span className='footer__copyright'>{`© itwaslazyday, ${new Date().getFullYear()}`}</span>
          <div className='footer__icons-wrapper'>
            <span>Made with</span>
            <svg className='footer__icons-react' width='25' height='25'>
              <use xlinkHref='img/sprite.svg#react'/>
            </svg>
            <span>&</span>
            <svg className='footer__icons-heart' width='25' height='25'>
              <use xlinkHref='img/sprite.svg#heart'/>
            </svg>
          </div>
        </div>
      </div>
    </footer>
  );
}

export {SiteFooter};
