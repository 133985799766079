import Logo from 'components/logo/logo';
import { AppRoute } from 'const';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated, config} from '@react-spring/web';
import './intro-page.css';

function IntroPage() {
  const navigate = useNavigate();

  const styles = useSpring({
    from: {width: 0},
    to: {width: 100},
    config: config.molasses,
    delay: 300,
    onResolve(result, ctrl, item) {
      navigate(AppRoute.Main);
    },
  });

  return (
    <animated.section className="intro">
      <div className="intro__inner">
        <Logo block={'footer'}/>
        <animated.div className="intro__cover" style={{width: styles.width.to((unit) => `${unit}%`)}}/>
      </div>
    </animated.section>
  );
}

export default IntroPage;
