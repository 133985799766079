import DevImage from 'components/dev-image/dev-image';
import Logo from 'components/logo/logo';
import { AppRoute } from 'const';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormState } from 'types/types';
import './contact-page.css';

const labels: {[index: string]: string} = {
  name: 'Ваше имя',
  email: 'Ваша почта',
  subject: 'Тема письма'
};

function ContactPage(): JSX.Element {
  const navigate = useNavigate();
  const initialFormState = {name: '', email: '', subject: '', message: ''};
  const initialDeliveryState = {isPosted: false, isError: false};

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [deliveryState, setDeliveryState] = useState(initialDeliveryState);

  const handleFormData = (evt: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({...formState, [evt.target.name]: evt.target.value});
  };

  const handleFormSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const formData = new FormData();
    Object.keys(formState).map((key) => formData.append(key, formState[key]));
    const response = fetch('/send_email.php', {
      method: 'POST',
      body: formData,
    })
      .then((result) => {
        if (result.ok) {
          setFormState(initialFormState);
          setDeliveryState({isPosted: true, isError: false});
        } else {
          setDeliveryState({isPosted: false, isError: true});
        }
      });
  };

  const resetDeliveryState = () => {
    setTimeout(() => {
      deliveryState.isPosted ? navigate(AppRoute.Main) : setDeliveryState(initialDeliveryState);
    }, 4000);
  };

  const getStatusMessage = () => {
    resetDeliveryState();
    return (
      <div className='contact__received-wrapper'>
        <svg width='100' height='100' className='contact__received-icon'>
          <use xlinkHref={`img/sprite.svg#received-${deliveryState.isPosted ? 'ok' : 'error'}`}/>
        </svg>
        <p className='contact__received-note'>
          {deliveryState.isPosted ?
            <>Ваше письмо успешно доставлено!<br/>Я обязательно на него отвечу.</> :
            <>Увы, не получилось отправить форму.<br/>Попробуем еще раз?</>}
        </p>
      </div>
    );
  };

  const checkFormError = () =>
    deliveryState.isError ?
      getStatusMessage() :
      <>
        <DevImage block='contact'/>
        <h1 className='contact__title tertiary-heading'>Спасибо за проявленный интерес!<br/>Как я могу Вам помочь?</h1>
        <form className='contact__form form' method='POST' onSubmit={handleFormSubmit}>
          {Object.keys(labels).map((key) => (
            <>
              <label className='form__label' htmlFor={key} key={key}>
                {labels[key]}<span>*</span>
              </label>
              <input
                type={key === 'email' ? 'email' : 'text'}
                className='form__input form__field'
                onChange={handleFormData}
                value={formState[key]}
                id={key}
                name={key}
                required
              />
            </>
          ))}
          <label className='form__label' htmlFor='message'>
            Введите сообщение<span>*</span>
          </label>
          <textarea
            className='form__textarea form__field'
            id='message'
            name='message'
            onChange={handleFormData}
            rows={8}
            required
            value={formState.message}
          />
          <button
            type='submit'
            className='form__submit button-basic'
          >
            Отправить
          </button>
        </form>
      </>;

  return (
    <section className='contact'>
      <div className='container'>
        <div className='contact__inner'>
          <Logo block='contact'/>
          {deliveryState.isPosted ? getStatusMessage() : checkFormError()}
        </div>
      </div>
    </section>
  );
}

export default ContactPage;
