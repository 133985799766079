import { AppRoute } from 'const';
import { Link } from 'react-router-dom';
import './logo.css';

type LogoProps = {
  block: string;
};

function Logo({block}: LogoProps): JSX.Element {

  return (
    <Link to={AppRoute.Main} className={`${block}__logo-link logo`}>
      <img className='logo__image' width='50' height='50'
        src={`img/${block === 'footer' ? 'footer' : 'header'}-logo.svg`} alt='Логотип разработчика'
      />
    </Link>
  );
}

export default Logo;
