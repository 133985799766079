import ContactPage from 'pages/contact-page/contact-page';
import IntroPage from 'pages/intro-page/intro-page';
import MainPage from 'pages/main-page/main-page';
import NotFoundPage from 'pages/not-found-page/not-found-page';
import { Route, Routes } from 'react-router-dom';
import { AppRoute } from '../../const';

function App(): JSX.Element {

  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={<IntroPage/>}
        />
        <Route
          path={AppRoute.Main}
          element={<MainPage/>}
        />
        <Route
          path={AppRoute.Contact}
          element={<ContactPage />}
        />
        <Route
          path={AppRoute.NotFound}
          element={<NotFoundPage />}
        />
      </Route>
    </Routes>
  );
}

export default App;
