import ProjectItem from 'components/project-item/project-item';
import { Project } from 'types/types';
import './projects-list.css';

type ProjectProps = {
  projects: Project[];
}

function ProjectsList({projects}: ProjectProps): JSX.Element {

  return (
    <ul className='projects__list list-reset'>
      {projects.map((project) => <ProjectItem key={project.id} project={project}/>)}
    </ul>
  );
}

export default ProjectsList;
