import './project-item.css';
import { Project } from 'types/types';

type ProjectProps = {
  project: Project;
}

function ProjectItem({project}: ProjectProps): JSX.Element {

  return (
    <li className='projects__list-item project'>
      <figure className='project__figure'>
        <picture>
          {/* <source type="image/webp" srcSet='img/projects/antools@1x.webp 1x, img/projects/antools@2x.webp 2x'/> */}
          <img className="project__image" src={`img/projects/${project.imageSrc}@1x.png`} srcSet={`img/projects/${project.imageSrc}@2x.png 2x`} alt={`Проект "${project.title}"`} width='350' height='230'/>
        </picture>
        <figcaption className='project__caption'>
          <h4 className='project__title'>{project.backSideText}</h4>
          <a className='project__details button-basic' href={project.url} target='_blank' rel='noreferrer'>
            Перейти
            <svg width='15' height='15' stroke='white'>
              <use xlinkHref='img/sprite.svg#chevron'/>
            </svg>
          </a>
        </figcaption>
      </figure>
    </li>
  );
}

export default ProjectItem;
