import './about.css';
import { tools } from 'data';

function About(): JSX.Element {

  return (
    <section className='about'>
      <div className='container'>
        <div className='about__inner'>
          <h2 className='about__title secondary-heading'>Привет!</h2>
          <p className='about__text'>
            Меня зовут Вячеслав, я начинающий разработчик.
            Уверенно верстаю и пишу на JS, но стараюсь больше фокусироваться на React.
            Требовательно отношусь к своей работе и обеими руками за семантику, доступность и лаконичность кода.
          </p>
          <div className='about__skills skills'>
            <h3 className='skills__title tertiary-heading'>Frontend Developer</h3>
            <p className='skills__text'>
              Я не боюсь браться за сложные и необычные для меня проекты, ошибаться, и постоянно учиться чему-то новому.
            </p>
            <p className='skills__languages'>
              <span>На чём я пишу:</span>
              HTML5, CSS3, JS, React, TypeScript
            </p>
            <span className='skills__tools'>Инструменты и навыки:</span>
            <ul className='skills__tools-list list-reset'>
              {tools.map((tool) =>
                <li className='skills__tools-item' key={tool}>{tool}</li>)}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
