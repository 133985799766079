import About from 'components/about/about';
import Feedback from 'components/feedback/feedback';
import Projects from 'components/projects/projects';
import Promo from 'components/promo/promo';
import { SiteFooter } from 'components/site-footer/site-footer';
import { SiteHeader } from 'components/site-header/site-header';
import './main-page.css';

function MainPage(): JSX.Element {

  return (
    <>
      <SiteHeader/>
      <main className='page-main main'>
        <Promo/>
        <About/>
        <Projects/>
        <Feedback/>
      </main>
      <SiteFooter/>
    </>
  );
}

export default MainPage;
