import './dev-image.css';

type DevImageProps = {
  block: string;
};

function DevImage({block}: DevImageProps) {
  return (
    <picture>
      <img className={`${block}__image dev-image`} src='img/kozachenko.jpg' srcSet='img/kozachenko.webp' alt='Фотография разработчика' width='160' height='160'/>
    </picture>
  );
}

export default DevImage;
