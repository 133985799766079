import Logo from 'components/logo/logo';
import {Link} from 'react-router-dom';
import './not-found-page.css';

function NotFoundPage(): JSX.Element {
  return (
    <section className="not-found">
      <div className="container">
        <div className="not-found__inner">
          <Logo block={'not-found'}/>
          <div className="not-found__wrapper">
            <img src='img/not-found-bg.svg' width='300' height='300' alt='Запрошенной страницы не существует'/>
            <p className="not-found__text">
              К сожалению, здесь пока нет такой страницы.<br/>Но, всегда можно вернуться
              <Link to="/" className="not-found__link link-reset"> домой.</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFoundPage;
