import DevImage from 'components/dev-image/dev-image';
import './promo.css';

function Promo(): JSX.Element {

  return (
    <section className='promo'>
      <div className='container'>
        <div className='promo__inner'>
          <h1 className='promo__title primary-heading'>Junior Frontend Developer</h1>
          <p className='promo__text'>Ни дня без строчки кода</p>
          <DevImage block='promo'/>
          <div className='promo__bottom-image'/>
        </div>
      </div>
    </section>
  );
}

export default Promo;
