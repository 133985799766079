import { Project } from 'types/types';

const projects: Project[] = [
  {
    id: 5,
    title: 'Antools',
    url: 'https://antools-itwaslazyday.vercel.app',
    backSideText: 'Сборник лучших инструментов для дизайнеров и web-разработчиков.',
    imageSrc: 'antools'
  },
  {
    id: 7,
    title: 'Исторические даты',
    url: 'https://only-history-itwaslazyday.vercel.app',
    backSideText: 'А вы помните, что произошло 26 апреля 1986 года?',
    imageSrc: 'history-dates'
  },
  {
    id: 9,
    title: 'Шесть городов',
    url: 'https://six-cities-itwaslazyday.vercel.app',
    backSideText: 'Исследуйте мир с Six Cities! Аренда жилья в самых красивых городах Европы.',
    imageSrc: 'six-cities'
  },
  {
    id: 8,
    title: 'Weather Forecast',
    url: 'https://weather-forecast-itwaslazyday.vercel.app/',
    backSideText: 'Завтра обещают ливень. Не забудьте взять зонтик!',
    imageSrc: 'weather-forecast'
  },
  {
    id: 4,
    title: 'Kekstagram',
    url: 'https://kekstagram-itwaslazyday.vercel.app/',
    backSideText: 'Почти Instagram! А может даже и лучше.',
    imageSrc: 'kekstagram'
  },
  {
    id: 1,
    title: 'Sedona',
    url: 'https://sedona-itwaslazyday.vercel.app/',
    backSideText: 'Седона - небольшой городок в Аризоне, заслуживающий большего!',
    imageSrc: 'sedona'
  },
  {
    id: 6,
    title: 'Cinemaddict',
    url: 'https://itwaslazyday.github.io/cinemaddict/',
    backSideText: 'Когда весь мир кино - в одном приложении.',
    imageSrc: 'cinemaddict'
  },
];

const tools: string[] = ['Git', 'Figma', 'БЭМ', 'LESS', 'Webpack', 'Redux', 'Redux Thunks', 'Axios', 'Jest', 'Vercel'];

const adjectives: string[] = [
  'Хорошего', 'Замечательного', 'Солнечного', 'Незабываемого', 'Лёгкого',
  'Плодотворного', 'Позитивного', 'Удачного', 'Прекрасного', 'Весёлого', 'Доброго', 'Насыщенного',
  'Чудесного', 'Радостного', 'Запоминающегося'
];

export {projects, tools, adjectives};
